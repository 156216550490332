
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiAuth, ApiBase } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "add-user-modal",
  components: {},
  emits: ["update-list"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const formRef = ref<null | HTMLFormElement>(null);
    const addUserModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      status: 25,
      username: "",
      email: "",
      password: "",
      firstname: "",
      lastname: "",
      mobile: "",
      telephone: "",
      address: "",
      zip_code: "",
      postcode: "",
      city: "",
      country_iso_2: "",
      language: "en_US",
      wms_default_warehouse: "",
    });

    const countryOptions = ref([]);
    const wareHouseOptions = ref([]);

    const rules = ref({
      username: [
        {
          required: true,
          message: t("user.userName") + t("common.isRequired"),
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: t("user.email") + t("common.isRequired"),
          trigger: "change",
        },
      ],
      password: [
        {
          required: true,
          message: t("user.password") + t("common.isRequired"),
          trigger: "change",
        },
      ],
      firstname: [
        {
          required: true,
          message: t("user.firstName") + t("common.isRequired"),
          trigger: "change",
        },
      ],
      lastname: [
        {
          required: true,
          message: t("user.lastName") + t("common.isRequired"),
          trigger: "change",
        },
      ],
      country_iso_2: [
        {
          required: true,
          message: t("user.country") + t("common.isRequired"),
          trigger: "change",
        },
      ],
      language: [
        {
          required: true,
          message: t("user.language") + t("common.isRequired"),
          trigger: "change",
        },
      ],
      wms_default_warehouse: [
        {
          required: true,
          message: t("user.warehouse") + t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiAuth.addUser(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                  hideModal(addUserModalRef.value);
                  emit("update-list");
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const getCountryOptions = () => {
      ApiBase.getCountryData()
        .then(({ data }) => {
          if (data.code == 0) {
            countryOptions.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getWareHouseOptions = async () => {
      const { data } = await ApiBase.getWarehouseData({
        function_type: "1",
      });
      if (data.code == 0) {
        wareHouseOptions.value = data.data;
      }
    };

    const handleDiscard = () => {
      hideModal(addUserModalRef.value);
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    onMounted(() => {
      getCountryOptions();
      getWareHouseOptions();
    });

    return {
      t,
      loading,
      formData,
      rules,
      formRef,
      addUserModalRef,
      submitButton,
      countryOptions,
      wareHouseOptions,
      submit,
      handleDiscard,
      resetForm,
    };
  },
});
