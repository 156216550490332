
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

interface Filter {
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  status: string | number;
}

export default defineComponent({
  name: "filter-user-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const formRef = ref<null | HTMLFormElement>(null);

    const formData = ref<Filter>({
      username: "",
      email: "",
      firstname: "",
      lastname: "",
      status: "",
    });

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    return {
      t,
      formData,
      formRef,
      submit,
      handleReset,
    };
  },
});
