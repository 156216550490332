import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-4" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "card-toolbar" }
const _hoisted_8 = {
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_9 = {
  type: "button",
  class: "btn btn-light-primary me-3",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end"
}
const _hoisted_10 = { class: "svg-icon svg-icon-2" }
const _hoisted_11 = {
  type: "button",
  class: "btn btn-primary",
  "data-bs-toggle": "modal",
  "data-bs-target": "#modal_add_user"
}
const _hoisted_12 = { class: "svg-icon svg-icon-2" }
const _hoisted_13 = { class: "card-body pt-0" }
const _hoisted_14 = {
  key: 0,
  class: "badge badge-light-success"
}
const _hoisted_15 = {
  key: 1,
  class: "badge badge-light-danger"
}
const _hoisted_16 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_FilterDropdown = _resolveComponent("FilterDropdown")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MBDatatable = _resolveComponent("MBDatatable")!
  const _component_AddUserModal = _resolveComponent("AddUserModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
            ]),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
              class: "form-control form-control-solid w-250px ps-14",
              placeholder: _ctx.t('common.search') + _ctx.t('user.users')
            }, null, 40, _hoisted_6), [
              [_vModelText, _ctx.search]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", _hoisted_9, [
              _createElementVNode("span", _hoisted_10, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen031.svg" })
              ]),
              _createTextVNode(_toDisplayString(_ctx.t("common.filter")), 1)
            ]),
            _createVNode(_component_FilterDropdown, {
              onSubmitFilter: _ctx.handleFilter,
              onResetFilter: _ctx.handleFilterReset
            }, null, 8, ["onSubmitFilter", "onResetFilter"]),
            _createElementVNode("button", _hoisted_11, [
              _createElementVNode("span", _hoisted_12, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr075.svg" })
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.t("common.add") + _ctx.t("user.user")), 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_MBDatatable, {
          loading: _ctx.loading,
          "table-data": _ctx.tableData,
          "table-header": _ctx.tableHeader,
          "enable-items-per-page-dropdown": true,
          "current-page": _ctx.currentPage,
          total: _ctx.total,
          "rows-per-page": _ctx.pageSize,
          onCurrentChange: _ctx.onCurrentPageChange,
          onItemsPerPageChange: _ctx.onRowsPerPageChange,
          onSort: _ctx.onColumnSort
        }, {
          "cell-id": _withCtx(({ row: user }) => [
            _createTextVNode(_toDisplayString(user.id), 1)
          ]),
          "cell-username": _withCtx(({ row: user }) => [
            _createVNode(_component_router_link, {
              to: _ctx.getRoute + user.id + '/overview',
              class: "text-gray-800 text-hover-primary mb-1"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(user.username), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          "cell-email": _withCtx(({ row: user }) => [
            _createTextVNode(_toDisplayString(user.email), 1)
          ]),
          "cell-firstname": _withCtx(({ row: user }) => [
            _createTextVNode(_toDisplayString(user.firstname), 1)
          ]),
          "cell-lastname": _withCtx(({ row: user }) => [
            _createTextVNode(_toDisplayString(user.lastname), 1)
          ]),
          "cell-status": _withCtx(({ row: user }) => [
            (user.status == 10)
              ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.t("user.activated")), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.t("user.deactivated")), 1))
          ]),
          "cell-actions": _withCtx(({ row: user }) => [
            _createVNode(_component_router_link, {
              to: _ctx.getRoute + user.id + '/settings',
              class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_16, [
                  _createVNode(_component_inline_svg, { src: "media/icons/duotune/art/art005.svg" })
                ])
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _: 1
        }, 8, ["loading", "table-data", "table-header", "current-page", "total", "rows-per-page", "onCurrentChange", "onItemsPerPageChange", "onSort"])
      ])
    ]),
    _createVNode(_component_AddUserModal, { onUpdateList: _ctx.updateList }, null, 8, ["onUpdateList"])
  ], 64))
}